import React from "react";
import "./JoinOurTeam.css";
import Video from "./Assets/Video.mp4";
import img01 from "../JoinOurTeam/Assets/Hybrid-01.png";
import img02 from "../JoinOurTeam/Assets/Chai-01.png";
import img03 from "../JoinOurTeam/Assets/Trilingual-01.png";
import Header from "../../../Component/Header/Header";
import Footer from "../../../Component/Footer/Footer";

function JoinOurTeam() {
 

  return (
    <>
      <Header />
      <div className="container-fluid my-1">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-12 my-3  text-center welcome ">
            <h1 className="fw-bold">Welcome to SHOD Work Lifestyle</h1>
            <p className="welcome_ptag text-muted">
              Your voice matters, and your ideas could be the next big thing!
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="player_wrapper01_Modern text-center">
              <video
                className="react_player01_Modern "
                src={Video}
                loop
                autoPlay
                muted
              >
                Your browser does not support HTML video.
              </video>
            </div>
          </div>
        </div>

        <div className="row mt-1 mt-lg-4">
          <div className="col-lg-3"></div>
          <div className=" col-lg-8 col-md-12 my-4 mt-4">
            <div className="button_applyy">
              <h1 className="heading-h1 fw-bold">Join Our Team{" "}</h1>
               <button type="button" className=" btn btn-primary btn1 mx-lg-5"
                onClick={() => {
                  document
                    .getElementById("ViewPositions")
                    .scrollIntoView({ behavior: "smooth" });
                }}
               >
                      View Open Positions
                    </button>
            </div>
          </div>
          <div className=" col-lg-1 col-md-12"></div>
        </div>

        <div className="row join-team mt-3">
          <div className="content1 col-lg-3 col-md-12"></div>
          <div className=" col-lg-8 col-md-12 my-4">
            <div className="container">
              <div className="row">
                <p className="para-p1">
                  Do you find inactiveness in your{" "}
                  <span className="colour-r1">current growth-rate</span> ? Are
                  you looking to:
                </p>

                <ul className="li-list">
                  <li>Deliver exceptional customer experiences (CX)?</li>
                  <li>
                    Move rapidly from idea to design and bring game-changing
                    products <br /> to market?
                  </li>
                  <li>
                    Uncover customer insights and map emerging market trends and
                    <br />
                    technologies?
                  </li>
                  <li>
                    Enhance brands by aligning commercial positioning, purpose,
                    and user <br />
                    experience?
                  </li>
                  <li>
                    Create and improve processes and tools to boost your teams’{" "}
                    <br />
                    collaboration?
                  </li>
                </ul>
              </div>
            </div>
            <div className="container mt-5" id="ViewPositions">
              <div className="row">
                <h1 className="heading1-h1 fw-bold">Current Open Positions</h1>
                <p className="position">
                  We are actively looking for result-oriented talents
                </p>
                <div className="row job-card">
                  <div className="col-12 col-md-6 col-lg-3">
                    <h6 className="d-flex justify-content-start result">
                      UI Developer
                    </h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result1">
                    <h6>Full-time</h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result_1">
                    <h6>Remote</h6>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 result2">
                    <button className="button-6">Apply</button>
                  </div>
                </div>
                <div className="row job-card">
                  <div className="col-12 col-md-6 col-lg-3">
                    <h6 className="d-flex justify-content-start result">
                      Front End Developer
                    </h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result1">
                    <h6>Full-time</h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result_1">
                    <h6>Remote</h6>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 result2">
                    <button className="button-6">Apply</button>
                  </div>
                </div>
                <div className="row job-card">
                  <div className="col-12 col-md-6 col-lg-3">
                    <h6 className="d-flex justify-content-start result">
                      Back End Developer
                    </h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result1">
                    <h6>Full-time</h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result_1">
                    <h6>Remote</h6>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 result2">
                    <button className="button-6">Apply</button>
                  </div>
                </div>
                <div className="row job-card">
                  <div className="col-12 col-md-3 col-lg-3">
                    <h6 className="d-flex justify-content-start result">
                      Full Stack Developer
                    </h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result1">
                    <h6>Full-time</h6>
                  </div>
                  <div className="col-6 col-md-6 col-lg-3 result_1">
                    <h6>Remote</h6>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 result2">
                    <button className="button-6">Apply</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mediatop">
              <div className="row">
                <h2 className="perks-h7">Perks & Benefits at SHOD </h2>
                <div className="perks">
                  <ul className="jointeam_ul">
                    <li>
                      <span>Innovative Culture:</span> Be part of a team that
                      values creativity and encourages out-of-the-box thinking.
                      We believe that diverse perspectives drive innovation
                    </li>
                    <li>
                      <span>Professional Growth: </span>
                      We invest in our employees' growth and development.
                      Through mentorship programs, workshops, and ongoing
                      training, we empower our team to reach new heights in
                      their careers.
                    </li>
                    <li>
                      <span>Impactful Work: </span>
                      Join a company that makes a difference. Your contributions
                      will have a meaningful impact.
                    </li>
                    <li>
                      {" "}
                      <span>Collaborative Environment :</span>
                      Work with a team of like-minded individuals who are
                      passionate about their craft. Collaboration is at the
                      heart of what we do, ensuring a supportive and motivating
                      workplace.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-1 col-md-12"></div>
        </div>
      </div>
      <div className="container-fluid marigin_contantiner Container_Modern1 ">
        <div className="row somefun">
          <div className="fun1">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0",
              }}
            >
              <hr
                style={{ flex: 1, border: "none", borderTop: "1px solid #000" }}
              />
              <span
                className="us here"
                style={{
                  padding: "0 10px",
                  whiteSpace: "nowrap",
                  fontWeight: "400",
                }}
              >
                Here's some fun factor about us
              </span>
              <hr
                style={{ flex: 1, border: "none", borderTop: "1px solid #000" }}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 hybid-image1">
            <img src={img01} alt="hybid-image" className="img-fluid" />
            <h5 className="hybrids">Hybrids</h5>
            <p className="one-p1">
              From ceramics to textiles, motion graphics to architecture,
              engineering to law - we draw expertise from more than one field.
            </p>
          </div>
          <div className="col-lg-4  col-md-12 chai-image">
            <img src={img02} alt="chai-image" className="img-fluid" />
            <h5 className="chai">300 Cups of Chai per Week</h5>
            <p className="one-p2">
              We are true chai fanatics. Ask our global SHOD visitors.
            </p>
          </div>
          <div className="col-lg-4  col-md-12 tri-image">
            <img src={img03} alt="tri-image" />
            <h5 className="tri">Tri-Lingual</h5>
            <p className="one-p3">
              Don’t be surprised to hear us switch between languages with as
              much ease as we switch between the studio and a brew pub.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JoinOurTeam;
