import React from "react";
import "./SolutionPage.css";
import Header from "../../../Component/Header/Header";
import { Link } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";

const SolutionPage = () => {
  return (
    <>
      <Header />
      <div className="container-fluid mt-1 contain2">
        <div className="row row1">
          <div className="col-lg-5 mb-1">
            <div className="p-4">
              <h2 className="h2-edit">Services</h2>
              <ul className="list-unstyled items">
                <a href="/ModernSupplySolutions">
                  Modern Supply Chain Solutions
                </a>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 mb-4">
            <div className="p-4">
              <h2 className="h2-edit">Industries</h2>
              <ul className="list-unstyled items">
                <a href="/Banking">Banking</a>
                <br />
                <a href="/HealthCarePage">Health Care</a>
                <br />
                <a href="/OilAndGas">Oil & Gas</a> <br />
                <a href="/ViewAll">View all industries</a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SolutionPage;
