import React from "react";
import Header from "../../../Component/Header/Header";
import Footer from "../../../Component/Footer/Footer";

const InsightsPage = () => {
  return (
    <>
      <Header />
      <div className="container-fluid mt-1 contain2">
        <div className="row row1">
          <div className="col-lg-5 mb-1">
            <div className="p-4 ">
              <h2 className="h2-edit">Comprehension</h2>
              <ul className="list-unstyled items">
                <a href="/WhyStephensAnyts">Why Stephens's Analytics</a>
                <br />
              </ul>
            </div>
          </div>
          <div className="col-lg-7 mb-4">
            <div className="p-4">
              <h2 className="h2-edit">Blogs</h2>
              <ul className="list-unstyled items">
                <a href="/AdoptingGenAI">
                  What CEOs Need to Know About the Costs of Adopting GenAI
                </a>
                <br />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InsightsPage;

{
  /* <a href="/AdoptingGenAI">
                  What CEOs Need to Know About the Costs of Adopting GenAI
                </a>
                <a href="/WhyStephensAnyts">Why Stephens's Analytics</a> */
}
